import React from "react";
import CadastroForm from "../components/CadastroForm";

const Formulario = () => {
  return (
    <div>
      <div className="cab">
        <div>
          <h1 className="solicit-title">Obter Passaporte</h1>
          <h4>Preencha as informações abaixo</h4>
        </div>
      </div>

      <div className="page">
        <CadastroForm />
      </div>
    </div>
  );
};

export default Formulario;
