import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AccordionSteps = () => {
  return (
    <div>
      <Accordion
        sx={{ backgroundColor: "transparent", boxShadow: "none" }} // Remove o fundo e sombra
        expanded
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ flexDirection: "row-reverse" }} // Mover a seta para a esquerda
        >
          <Typography variant="h6">O que é?</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft: 5, paddingRight: 5 }}>
          <Typography>
            Passaporte é um <strong>documento de viagem</strong>, ou seja, é um
            documento aceito como <strong>identificação</strong> do viajante em
            outro país. Nele podem ser registrados vistos, autorizações,
            entradas e saídas.
            <br />
            <br />
            Passaporte Diplomático e Passaporte Oficial ou de Serviço{" "}
            <strong>não são</strong>
            emitidos aqui: visite o serviço consular para sua emissão.
            <br />
            <br />
            Aqui, além do Passaporte comum, <strong>
              também são emitidos
            </strong>{" "}
            Passaporte para Estrangeiro e Laissez-Passer, desde que atendidas as
            condições legais.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{ backgroundColor: "transparent", boxShadow: "none" }} // Remove o fundo e sombra
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ flexDirection: "row-reverse" }} // Mover a seta para a esquerda
        >
          <Typography variant="h6">Quem pode utilizar este serviço?</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft: 5, paddingRight: 5 }}>
          <Typography>
            Este serviço se dedica exclusivamente a quem se encontra em
            território brasileiro e que atende aos requisitos legais, de acordo
            com a nacionalidade, brasileiro ou não brasileiro.
            <br /> <br />
            <center>
              <strong>RESUMO DAS ETAPAS</strong>
              <br />A imagem mostra as etapas do serviço que serão descritas a
              seguir:
              <br />
              <br />
              <img src="https://i.imgur.com/THoR0g1.png" />
            </center>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{ backgroundColor: "transparent", boxShadow: "none" }} // Remove o fundo e sombra
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ flexDirection: "row-reverse" }} // Mover a seta para a esquerda
        >
          <Typography variant="h6">
            Finalidades do Passaporte Brasileiro
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft: 5, paddingRight: 5 }}>
          <Typography>
            <strong>Identificação em viagens internacionais:</strong> O
            passaporte é o principal meio de identificação do cidadão brasileiro
            fora do país, sendo indispensável para a entrada e saída em outros
            territórios.
            <br />
            <br />
            <strong>Controle de imigração:</strong> As autoridades de imigração
            carimbam o passaporte ou registram eletronicamente as informações
            referentes à entrada e saída de cada país visitado.
            <br />
            <br />
            <strong>Solicitação de vistos:</strong> Em viagens para países que
            exigem visto de entrada, o passaporte é o documento utilizado para
            solicitação e recebimento do visto.
            <br />
            <br />
            <strong>Comprovação de nacionalidade:</strong> O passaporte atesta a
            cidadania brasileira, conferindo ao portador os direitos e deveres
            que lhe assistem no exterior.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{ backgroundColor: "transparent", boxShadow: "none" }} // Remove o fundo e sombra
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ flexDirection: "row-reverse" }} // Mover a seta para a esquerda
        >
          <Typography variant="h6">Outras informações</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft: 5, paddingRight: 5 }}>
          <Typography>
            <strong>Quanto tempo leva?</strong>
            <br />
            Entre 6 e 10 dia(s) útil(eis) é o tempo estimado para a prestação
            deste serviço.
            <br />
            <br />
            <strong>Informações adicionais ao tempo estimado</strong>
            <br />
            Em alguns períodos do ano, esse prazo pode variar por causa do
            aumento na procura, aproximação de período de férias escolares etc.
            <br />
            <br />
            <strong>
              Tratamento a ser dispensado ao usuário no atendimento{" "}
            </strong>
            <br />
            Em respeito aos princípios expressos na Lei nº 13.460/17, o usuário
            deve receber um atendimento pautado nas seguintes diretrizes:
            Urbanidade ― Respeito ― Acessibilidade ― Cortesia ― Presunção da
            boa-fé ― Igualdade ― Eficiência ― Segurança e Ética.
            <br />
            <br />
            <strong>
              Informação sobre quem tem direito a tratamento prioritário
            </strong>
            <br />
            As pessoas com deficiência, os idosos com idade igual ou superior a
            60 anos, as gestantes, as lactantes, as pessoas com crianças de colo
            e os obesos, conforme estabelecido na Lei 10.048 de 8 de novembro de
            2000, têm direito a atendimento prioritário. O próprio agendamento
            do atendimento permite que sua espera na unidade seja a menor
            possível.
            <br />
            <br />
            <center>
              <img src="https://i.imgur.com/VZ4GNrn.png" />
              <br />
              <br />
              <a href="/formulario">
                <button className="btn-pd">Iniciar Requerimento</button>
              </a>
            </center>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionSteps;
