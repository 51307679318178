import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import CheckIcon from "@mui/icons-material/Check";
import { Badge, GppGood } from "@mui/icons-material";

const steps = [
  { icon: <HomeIcon />, path: "/" },
  { icon: <Badge />, path: "/formulario" },
  { icon: <GppGood />, path: "/pagamento" },
];

const StepHeader = () => {
  const location = useLocation();
  const activeStep = steps.findIndex((step) => step.path === location.pathname);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step
            key={index}
            completed={index < activeStep}
            sx={{
              "& .MuiStepIcon-root": {
                color: index < activeStep ? "#e1effe" : "#9e9e9e", // Azul clarinho para completados e cinza para inativos
              },
              "& .MuiStepIcon-completed": {
                color: "#e1effe", // Cor azul clarinho quando completo
              },
              "& .MuiStepIcon-active": {
                color: "#e1effe", // Cor azul clarinho quando ativo
              },
              "& .MuiStepConnector-line": {
                borderColor: "#e1effe", // Cor da barra
                borderWidth: 5, // Espessura da barra
                marginTop: 0.4,
              },
            }}
          >
            <StepLabel
              StepIconComponent={({ completed, active }) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                    borderRadius: "50%",
                    backgroundColor:
                      completed || active ? "#e1effe" : "#9e9e9e",
                    color: completed || active ? "#1351b4" : "white",
                    padding: 5,
                  }}
                >
                  {completed ? <CheckIcon /> : step.icon}
                </div>
              )}
            />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepHeader;
