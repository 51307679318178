import React, { useEffect, useState } from "react";
import { Container, Typography, Button, TextField, Box } from "@mui/material";
import { QRCodeSVG } from "qrcode.react"; // Importando QRCodeSVG
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom"; // Importando useNavigate

const Pagamento = () => {
  const location = useLocation(); // Obtendo o objeto de localização
  const { invoiceData, numeroAleatorio } = location.state || {}; // Extraindo os dados do estado

  const [copied, setCopied] = useState(false);
  const navigate = useNavigate(); // Inicializando o useNavigate

  const formatarCPF = (cpf) => {
    // Remove todos os caracteres que não são dígitos
    const apenasNumeros = cpf.replace(/\D/g, "");

    // Verifica se o CPF tem 11 dígitos
    if (apenasNumeros.length !== 11) {
      throw new Error("O CPF deve ter 11 dígitos.");
    }

    // Formata o CPF
    return `${apenasNumeros.substring(0, 3)}.${apenasNumeros.substring(
      3,
      6
    )}.${apenasNumeros.substring(6, 9)}-${apenasNumeros.substring(9, 11)}`;
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(invoiceData.invoice.fatura.pix.payload)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reseta a mensagem após 2 segundos
      });
  };

  useEffect(() => {
    !invoiceData && navigate("/");
  });

  return (
    <div>
      <div className="cab">
        <div>
          <h1 className="solicit-title">Concluir Solicitação do Passaporte</h1>
          <h4>Finalize o pagamento da taxa de emissão abaixo</h4>
        </div>
      </div>

      <div className="page pay">
        <p>
          <strong>Número de Protocolo:</strong>
          <br /> 1.0093.{numeroAleatorio}
          <br />
          <br />
          Após efetuar o pagamento da taxa você receberá um e-mail com a
          confirmação do seu requerimento e a certidão de cadastramento será
          encaminhado para você dentro de 5 dias úteis. Verifique se possuí
          todos os documentos exigidos na etapa anterior em mãos.
          <br />
          <br />
          Use o aplicativo do seu banco para ler o <strong>QRCode</strong>{" "}
          abaixo ou toque no botão <strong>COPIAR CÓDIGO PIX</strong> para
          copiar o código e realizar a transação no aplicativo do seu banco.
        </p>
        {invoiceData && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <h3>R$257,25</h3>
            <QRCodeSVG
              value={invoiceData.invoice.fatura.pix.payload}
              size={200}
            />{" "}
            {/* Gerador de QR Code */}
            <div className="protocol">
              <strong>Nome:</strong> {invoiceData.costumer.customers.name}
              <br />
              <strong>CPF:</strong>{" "}
              {formatarCPF(invoiceData.costumer.customers.document)}
              <br />
            </div>
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              size="small"
              value={invoiceData.invoice.fatura.pix.payload}
              InputProps={{
                disabled: true,
              }}
            />
            <button onClick={handleCopy} className="btn-bl">
              Copiar Código Pix
            </button>
            {copied && (
              <Typography color="success.main" marginTop={1}>
                Código Pix copiado!
              </Typography>
            )}
          </Box>
        )}
      </div>
    </div>
  );
};

export default Pagamento;
