import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Container } from "@mui/material";
import Home from "./pages/Home";
import Formulario from "./pages/Formulario";
import Pagamento from "./pages/Pagamento";
import StepHeader from "./components/StepHeader";

const App = () => {
  return (
    <Router>
      <Container>
        <div className="topo">
          <img src="https://i.imgur.com/6EfZIcd.png" />
        </div>
        <StepHeader />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/formulario" element={<Formulario />} />
          <Route path="/pagamento" element={<Pagamento />} />
        </Routes>
      </Container>
      <div className="footer">
        <Container>
          <div>
            <img src="https://i.imgur.com/nWnPLb8.png" width="150" />
          </div>
        </Container>

        <div className="subfooter">
          Todo o conteúdo deste site está publicado sob a licença{" "}
          <strong>
            Creative Commons Atribuição-SemDerivações 3.0 Não Adaptada.
          </strong>
        </div>
      </div>
    </Router>
  );
};

export default App;
