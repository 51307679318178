import React from "react";
import AccordionSteps from "../components/AccordionSteps";

const Home = () => {
  return (
    <div>
      <div className="cab">
        <div>
          <h1 className="solicit-title">Obter Passaporte</h1>
          <h4>
            "Tirar passaporte", "Tirar laissez-passer", "Emissão de passaporte"
          </h4>
        </div>
        <a href="/formulario">
          <button className="btn-pd">Iniciar Requerimento</button>
        </a>
      </div>

      <p className="mod">Última Modificação: 21/09/2024</p>

      <div className="page">
        <AccordionSteps />
      </div>
    </div>
  );
};

export default Home;
